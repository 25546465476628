import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Route } from "@angular/compiler/src/core";
import { Injectable } from "@angular/core";
import {
  CanLoad,
  Router,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import liff from "@line/liff/dist/lib";
import { environment } from "src/environments/environment";
import { LoadingService } from "../loading.service";
import { UserInfoService } from "../userInfo.service";

@Injectable()
export class LoginGuard implements CanLoad {
  public constructor(
    protected userInfoService:UserInfoService,
    protected loadingService:LoadingService,
    protected readonly http:HttpClient,
  ) {
  }

  public async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve,reject)=>{
      try {
        this.loadingService.loader$.next(true)
        liff.init({ liffId: environment.liffId }).then(async function() {
          const isLoggedIn = liff.isLoggedIn();
          if(!isLoggedIn){
            await liff.login()
          }
          else if(!this.userInfoService.userId){
            const data = await liff.getProfile()
            this.userInfoService.userId = data.userId
            const accessToken = await liff.getAccessToken()
            this.userInfoService.accessToken = accessToken
            const header = new HttpHeaders(
              {
                'userId': `${data.userId}`,
                'accessToken': `${accessToken}`
              })
            const infoRes = await this.http.get(environment.serverUrl+'/auth/info',{ headers: header }).toPromise()
            if(infoRes){
              const {roleLevel,allowPath} = infoRes
              this.userInfoService.roleLevel = roleLevel
              this.userInfoService.allowPath = allowPath
            }
          }
          this.loadingService.loader$.next(false)
          resolve(true)
        }.bind(this)).catch(function(error) {
          console.error(error);
          reject()
        });
      } catch (error) {
        console.error(error);
        reject()
      }
    })
  }
}
