import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NavHeadComponent } from './component/nav-head/nav-head.component';
import { UserInfoService } from './userInfo.service';
import { LoginGuard } from './guard/login.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingService } from './loading.service';
@NgModule({
  declarations: [
    AppComponent,
    NavHeadComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot([
      {
        path: "report",
        loadChildren: () =>
          import("./pages/report/report.module").then((m) => m.ReportModule),
        canLoad: [LoginGuard],
      },
      {
        path: "**",
        redirectTo: "/report",
        pathMatch: 'full'
      },
    ]),
  ],
  providers: [LoginGuard,UserInfoService,LoadingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
