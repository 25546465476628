import { Component, OnInit } from '@angular/core';
import { LoginGuard } from './guard/login.guard';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public loginGuard: LoginGuard,
    public loadingService: LoadingService
  ) {
    this.loadingService.loader$.subscribe(loading=>{
      this.loading = loading
    })
  }
  public loading = false

  ngOnInit(): void {
  }
}
