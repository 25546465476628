import { Component } from '@angular/core';

@Component({
  selector: 'nav-head',
  templateUrl: './nav-head.component.html',
  styleUrls: ['./nav-head.component.scss']
})
export class NavHeadComponent {
  constructor() { }
}
