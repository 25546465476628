import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class UserInfoService {
    constructor(
        protected readonly http:HttpClient,
    ){
    }
    public userId:string = '';
    public accessToken:string = '';
    public roleLevel:string = '';
    public allowPath:Array<string> = []
}
